import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import { useQuery } from 'react-apollo';
import { useCart } from '@jetshop/core/components/Query/CartProvider';
import PopupContent from './PopUpContent';
import GetContentByIdQuery from './../../components/GetContentByIdQuery.gql';
import cartQuery from '../Cart/CartQuery.gql';
import { theme } from '../Theme';

const Overlay = styled('div')`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupWrapper = styled('div')`
  background: white;
  width: 100%;
  max-width: 440px;
  padding: 24px;
  margin: 24px;
`;

const usePopupProperties = () => {
  const { data } = useQuery(GetContentByIdQuery, {
    variables: { ids: theme.popUpId },
  });

  if (!data?.content?.content[0]?.items.length) return {};

  const props = data.content.content[0]?.items[0]?.properties || [];
  const getProperty = (name) => props.find((item) => item.name === name)?.value?.value;

  return {
    isActive: getProperty('bool') === true,
    header: getProperty('header'),
    message: getProperty('message'),
    category: props.find((item) => item.name === 'category')?.value,
    image: props.find((item) => item.name === 'image')?.value,
    timeProp: parseInt(getProperty('time'), 10) || 0,
  };
};

const Popup = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const { isActive, header, message, category, image, timeProp } = usePopupProperties();
  const { cart } = useCart(cartQuery);
  const itemsInCart = cart?.totalQuantity || 0;

  useEffect(() => {
    let timer;
    if (itemsInCart === 0 && isActive) {
      timer = setTimeout(() => setShowPopUp(true), timeProp * 1000);
    } else {
      setShowPopUp(false);
    }
    return () => clearTimeout(timer);
  }, [itemsInCart, isActive, timeProp]);

  if (!isActive) return null;

  return (
    showPopUp &&
    itemsInCart === 0 && (
      <Overlay>
        <PopupWrapper>
          <PopupContent
            open={showPopUp}
            handleEscCallback={setShowPopUp}
            header={header}
            message={message}
            category={category}
            image={image}
          />
        </PopupWrapper>
      </Overlay>
    )
  );
};

export default Popup;
