import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPage from './CategoryPage/CategoryPage';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import pagePreviewQuery from './PagePreviewQuery.gql';
import FavouritesPage from './Favourites/FavouritesPage';
import FavouritesFlyout from './Favourites/FavouritesFlyout';
import { FavouritesProvider } from './Favourites/useFavourites';
import Livechat from './Theme/Livechat';
import { OrderForm } from './CaratCalculator/OrderForm';
import { theme } from './Theme';
import { TryggHandelScript } from './Layout/Footer/TryggHandelLogo';

import '@jetshop/ui/Theme/sanitizeCss';
import '../globalStyles';
import GetanewsletterSignup from './Getanewsletter/GetanewsletterSignup';
import Popup from './Popup/Popup';

const Store = loadable(() => import('./Store/Store'), {
  fallback: LoadingPage
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: LoadingPage
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: LoadingPage
});

// const LogInPage = loadable(() => import('./Auth/LogInPage'), {
//   fallback: LoadingPage
// });

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: LoadingPage
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: LoadingPage
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: LoadingPage
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: LoadingPage
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: LoadingPage
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: LoadingPage
});
// const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
//   fallback: LoadingPage
// });

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: LoadingPage
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: LoadingPage
});

// const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
//   fallback: LoadingPage
// });


function Shop() {
  return (
    <GenericError>
      <ModalProvider>
        <FavouritesProvider>
          <Container>
            <LoadingBar />
            <CustomFont
              primaryFont={theme.fonts.primary}
              injectCss={loadFontCss}
            />
            <TryggHandelScript />
            <Helmet titleTemplate="%s" defaultTitle="Smycka" />
            <Header />
            <Content>
              <PaginationProvider defaultProductsPerPage={24}>
                <FavouritesFlyout />
                <Switch>
                  <Route exact path="/" component={LoadableStartPage} />
                  <Route path="/search" component={LoadableSearchPage} />
                  <Route path="/guldraknare-formular" component={OrderForm} />
                  <Route path="/signup" component={NotFound} />
                  <Route path="/login" component={NotFound} />
                  <Route path="/stores" component={StoreLocator} />
                  <Route path="/butiker" component={StoreLocator} />
                  <Route path="/store/:id" component={Store} />
                  <Route path="/butik/:id" component={Store} />
                  <Route path="/tree" component={NavTreePage} />
                  <Route path="/my-pages" component={NotFound} />
                  <Route path="/favoriter" component={FavouritesPage} />
                  <Route path="/nyhetsbrev" component={GetanewsletterSignup} />
                  <Route path="/tack-nyhetsbrev" component={GetanewsletterSignup} />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    path="/reset-password/:token"
                    component={ResetPassword}
                  />
                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        productPage={LoadableProductPage}
                        pageQuery={pagePreviewQuery}
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        categoryPage={CategoryPage}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={CategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
            </Content>
            <Popup />
            <Footer />
            <Livechat />
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={['sortOrderChange', 'filterChange']}
            />
          </Container>
        </FavouritesProvider>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
