import AlFrescoOtf from './AlFresco.otf';
import GothamSSMBookWoff from './GothamSSm-Book_Web.woff';
import GothamSSMBookWoff2 from './GothamSSm-Book_Web.woff2';
import GothamSSMLightWoff from './GothamSSm-Light_Web.woff';
import GothamSSMLightWoff2 from './GothamSSm-Light_Web.woff2';
import GothamSSMBookItalicWoff from './GothamSSm-BookItalic_Web.woff';
import GothamSSMBookItalicWoff2 from './GothamSSm-BookItalic_Web.woff2';
import GothamSSMBookMediumWoff from './GothamSSm-Medium_Web.woff';
import GothamSSMBookMediumWoff2 from './GothamSSm-Medium_Web.woff2';
import GothamSSMBookMediumItalicWoff from './GothamSSm-MediumItalic_Web.woff';
import GothamSSMBookMediumItalicWoff2 from './GothamSSm-MediumItalic_Web.woff2';

import { css } from 'linaria';

/* Regular font */

export default function loadCss() {
  return css`

  @font-face {
  	font-family: 'HCo Gotham SSm';
  	src:url('${GothamSSMLightWoff}') format('woff2'),
  		url('${GothamSSMLightWoff2}') format('woff');
  	font-weight: 300;
  	font-style: normal;
  }
  @font-face {
  	font-family: 'HCo Gotham SSm';
  	src:url('${GothamSSMBookWoff}') format('woff2'),
  		url('${GothamSSMBookWoff2}') format('woff');
  	font-weight: 400;
  	font-style: normal;
  }

  @font-face {
  	font-family: 'HCo Gotham SSm';
  	src:url('${GothamSSMBookItalicWoff2}') format('woff2'),
  		url('${GothamSSMBookItalicWoff}') format('woff');
  	font-weight: 400;
  	font-style: italic;
  }

  @font-face {
  	font-family: 'HCo Gotham SSm';
  	src:url('${GothamSSMBookMediumWoff2}') format('woff2'),
  		url('${GothamSSMBookMediumWoff}') format('woff');
  	font-weight: 500;
  	font-style: normal;
  }

  @font-face {
  	font-family: 'HCo Gotham SSm';
  	src:url('${GothamSSMBookMediumItalicWoff2}') format('woff2'),
  		url('${GothamSSMBookMediumItalicWoff}') format('woff');
  	font-weight: 500;
  	font-style: italic;
  }

  @font-face {
    font-family: 'AlFresco';
    src: url(${AlFrescoOtf}) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
`;
}
