import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Cross } from '../../svg/IconCross.svg';
import { theme } from '../Theme';
import Image from './../Theme/Shortcodes/Image';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';
import t from '@jetshop/intl';

const Wrapper = styled('div')`
  position: relative;
  h2 {
    font-size: 24px;
  }
  p {
    margin-top: 16px;
    font-size: 16px;
  }

  svg {
    stroke: ${theme.colors.blueDarkHover};
    height: 12px;
    width: 12px;
  }
  .image {
    margin: 16px 0 24px;
  }
`;

const CloseButton = styled('button')`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
`;

const PopupContent = ({ open, handleEscCallback, header, message, product, category, image }) => {

  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === 'Escape') {
        console.log('Escape key pressed!', !open);
        handleEscCallback(!open);
      }
    };

    if (open) {
      window.addEventListener('keydown', handleEscKey);
      return () => {
        window.removeEventListener('keydown', handleEscKey);
      };
    }
  }, [open, handleEscCallback]);

  return (
    <Wrapper>
      <CloseButton onClick={() => handleEscCallback(!open)}>
        <Cross />
      </CloseButton>
      <h2>{header || 'no title'}</h2>
      <p>{message || 'no message'}</p>
      <Image
        src={image.value}
        alt={product?.images[0]?.title}
        cover={true}
        className={'image'}
      />
      <Link
        to={category?.primaryRoute?.path}
        aria-label="Link to category"
        onClick={() => handleEscCallback(!open)}
      >
        <Button>{t('Read more')}</Button>
      </Link>
    </Wrapper>
  );
};

export default PopupContent;
